import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index.vue'),
        meta: {
            isShow: true,
            tap: 'one',
            title: '八方电子招投标平台',
            keepAlive: true
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/news/index.vue'),
        meta: {
            isShow: true,
            tap: 'two',
            title: '新闻公告'
        }
    },
    {
        path: '/newsPhone',
        name: 'newsPhone',
        component: () => import('@/views/newsPhone/index.vue'),
        meta: {
            isShow: true,
            tap: 'two',
            title: '新闻公告',
            keepAlive: true
        }
    },
    {
        path: '/winning',
        name: 'winning',
        component: () => import('@/views/winning/index.vue'),
        meta: {
            isShow: false,
            title: '公告'
        }
    },
    {
        path: '/winningPhone',
        name: 'winningPhone',
        component: () => import('@/views/winningPhone/index.vue'),
        meta: {
            isShow: false,
            title: '公告'
        }
    },
    {
        path: '/Tool',
        name: 'Tool',
        component: () => import('@/views/ca/index.vue'),
        meta: {
            isShow: true,
            tap: 'six',
            title: '常用工具'
        }
    },
    {
        path: '/ca',
        name: 'ca',
        component: () => import('@/views/cabanli/index.vue'),
        meta: {
            isShow: false,
            tap: 'five',
            title: 'CA办理'
        }
    },
    {
        path: '/Baohan',
        name: 'Baohan',
        component: () => import('@/views/baohan/index.vue'),
        meta: {
            isShow: false,
            tap: 'four',
            title: '保函办理'
        }
    },
    {
        path: '/zbinfo',
        name: 'zbinfo',
        component: () => import('@/views/zbInfo/index.vue'),
        meta: {
            isShow: true,
            tap: 'three',
            title: '招标信息'
        }
    },
    {
        path: '/zbInfoPhone',
        name: 'zbInfoPhone',
        component: () => import('@/views/zbInfoPhone/index.vue'),
        meta: {
            isShow: true,
            tap: 'three',
            title: '招标信息',
            keepAlive: true
        }
    },
    {
        path: '/content',
        name: 'content',
        component: () => import('@/views/lawContent/index.vue'),
        meta: {
            isShow: false,
            title: '新闻内容'
        }
    },
    {
        path: '/contentPhone',
        name: 'contentPhone',
        component: () => import('@/views/lawContentPhone/index.vue'),
        meta: {
            isShow: false,
            title: '新闻内容'
        }
    },
]

//全局路由守卫----初始化的时候被调用，每次路由切换之前被调用


const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    window.document.title = to.meta.title;
    next();
})

export default router
