import request from "@/util/request";
import { Message } from "element-ui";

/**
 * 移动端首页平台总交易额数量
 * @params  角色ID
 */
export async function homeStatistics(params) {
  const res = await request.get(`item/winning/homeStatistics`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}
/**
 * 轮播图接口
 * @params  角色ID
 */
export async function queryCarouselChart(params) {
  const res = await request.get(`item/carousel-chart/queryCarouselChart`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}
/**
 * 根据选项查内容
 * @params  角色ID
 */
export async function homeWinning(params) {
  const res = await request.get(`item/winning/homeWinning`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 澄清答疑数据
 * @param params
 * @returns {Promise<*>}
 */
export async function queryHomeClarify(params) {
  const res = await request.get(
    `item/clarification/queryHomeClarify`,
    {
      params,
    }
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 查询公告内容
 * @id  公告id
 */
export async function queryWinningAllType(id) {
  const res = await request.get(
    `item/winning/queryWinningAllType?winningId=` + id
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  修改阅读量
 * @id  公告id
 */
export async function yueDuL(id) {
  const res = await request.get(`item/winning/yueDuL?winningId=` + id);
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 修改答疑澄清阅读量
 * @param id
 * @returns {Promise<*>}
 */
export async function editByStatus(id) {
  const res = await request.get(
    `item/clarification/editByStatus?Id=` + id
  );
  console.log(res, "res");
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 查询澄清补遗内容
 * @param id
 * @returns {Promise<*>}
 */
export async function queryHomeClarifyOne(id) {
  const res = await request.get(
    `item/clarification/queryHomeClarifyOne?clarifyId=${id}`
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  查询工具
 *
 */
export async function queryMessageByCategory() {
  const res = await request.get(
    `item/message-service/queryMessageByCategory?category=1`
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  查询附件
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryFJInfoByWinningId(winningId, fileType) {
  const res = await request.get(
    `file/bid-file/queryFJInfoByWinningId?winningId=${winningId}&fileType=${fileType}`
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 查询澄清文件
 * @param params
 * @returns {Promise<*>}
 */
export async function queryFiles(params) {
  const res = await request.get(`item/bid-file/queryFiles`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 下载招标文件列表
 * @param identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function queryBidFileByWinningId(params) {
  const res = await request.get("/item/winning/queryBidFileByWinningId", {
    params,
  });
  if (res.data.code === 0) {
    return res.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 下载招标文件
 * @param identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function queryBidFileByPackageId(params) {
  const res = await request.get("/file/bid-file/queryBidFileByPackageId", {
    params,
  });
  if (res.data.code === 0) {
    return res.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 下载澄清文件
 * @param identifier
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function downloadFile(params) {
  const res = await request.get("/file/bfFile/downloadFile", {
    params,
  });
  if (res.data.code === 0) {
    return res.data;
  } else {
    console.log("上传失败merge500----downloadFile");
  }
  return Message.error(res.data.msg || res.data.message);
}
/**
 * 图片回显
 * */
export async function queryImage(fileUrl) {
  const res = await request.get(
    "/file/v1/minio/tasks/queryImage?fileUrl=" + fileUrl
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  招标信息+建设工程
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryWinningIndexJSGC(params) {
  const res = await request.get(`item/winning/queryWinningIndexJSGC`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  招标信息+政府采购
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryWinningIndexZFCG(params) {
  const res = await request.get(`item/winning/queryWinningIndexZFCG`, {
    params,
  });
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 答疑澄清 +建设工程 + 政府采购
 * @param params
 * @returns {Promise<*>}
 */
export async function queryClarifyIndexJSGC(params) {
  const res = await request.get(
    `item/clarification/queryClarifyIndexJSGC`,
    { params }
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}
/**
 *  政策法规
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryPoliciesRegulations(params) {
  const res = await request.get(
    `item/policies-regulations/queryPoliciesRegulations`,
    { params }
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  新闻咨询
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryPlatformInformation(params) {
  const res = await request.get(
    `item/platform-information/queryPlatformInformation`,
    { params }
  );
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  新增公告质疑
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function addNoticeQuestion(params) {
  const res = await request.post(
    `item/notice/question/addNoticeQuestion`,
    params
  );
  if (res.data.code === 0) {
    return res.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 *  公告质疑列表
 * @winningId 公告id
 * @fileType 问价类型
 */
export async function queryNoticeQuestionList(params) {
  const res = await request.post(
    `item/notice/question/queryNoticeQuestionList`,
    params
  );
  if (res.data.code === 0) {
    return res.data;
  }
  return Message.error(res.data.msg || res.data.message);
}

/**
 * 查询动态参数
 * @param params
 */
export async function queryPlatformPath(params) {
  const res = await request.get(`item/platform-path/queryPlatformPath`, params);
  if (res.data.code === 0) {
    return res.data.data;
  }
  return Message.error(res.data.msg || res.data.message);
}
