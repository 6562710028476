import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userUrl: "",//用户登录
        expertUrl: "",//专家登录
        projectInfoServe: {},//项目信息
        oldUrl: "https://hb.bafanghaoda.com",//回到旧版
        websiteUrl: "https://hb.bafanghaoda.com/dzbh/website",//保函办理
        ggzUrl: "https://hebcaonline.hebca.com/Hebca/main_ggzy_2.jsp?onlineType=4&projectType=bfhztb",//CA办理
        scrollbarLoading: false,//滚动条加载状态
        isPhone: null,//是否手机端
    },
    mutations: {
        setProjectInfoServe(state, value) {
            state.projectInfoServe = value;
            state.userUrl = value.homePagePath + '/business/login';
            state.expertUrl = value.evaluationPath + '/reviewa/evaluationPackageIndex';
        },
    },
    actions: {
        setProjectInfoServe({ commit }, value) {
            commit('setProjectInfoServe', value)
        },
    }
});